<template>
  <div class="row mt--lg">
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Nome:</b> {{ model.name }}
      </getecma-p>
    </div>
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Unidade de Medida:</b> {{ capacity ? capacity.name : 'Carregando...' }}
      </getecma-p>
    </div>
  </div>
</template>

<script>

import { toastError } from '@/services/toastService';
import { getCapacityById } from '@/modules/capacity/capacity.service';

export default {
  name: 'GetecmaModelViewInformation',
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      capacity: null,
    };
  },
  mounted() {
    this.getCapacityById();
  },
  methods: {
    getCapacityById() {
      getCapacityById(this.model.capacity_id)
        .then(data => {
          this.capacity = data;
        })
        .catch(() => toastError('Erro ao obter unidade de medida por ID'));
    },
  },
};
</script>
