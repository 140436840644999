<template>
  <getecma-single-content-layout content-class="scroll pe--xxl">
    <template v-if="model" #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mt--xl" />
      <getecma-row justify="center" class="d-flex align-items--center d-xs-flex-column">
        <getecma-col :xs="24" :span="17" justify="left">
          <getecma-model-view-information :model="model" />
        </getecma-col>
      </getecma-row>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { toastError } from '@/services/toastService';
import { getModelById } from '@/modules/model/model.service';
import { MY_SPACE_URL } from '@/modules/my-space/my-space.constants';

import GetecmaModelViewInformation from '@/modules/model/components/ModelViewComponent.vue';
import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';

export default {
  name: 'GetecmaModelViewPage',
  components: {
    GetecmaModelViewInformation,
    GetecmaSingleContentLayout,
    GetecmaBreadcrumb,
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Modelos', path: MY_SPACE_URL.models.path },
        { name: 'Visualizar', path: this.$route.path },
      ],
      model: null,
      pagination: {
        page: 1,
        limit: 5,
      },
    };
  },
  computed: {
    model_id() {
      return this.$route.params?.id;
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      getModelById(this.model_id)
        .then(data => {
          this.model = data;
        })
        .catch(() => toastError('Erro ao obter modelo por ID'));
    },
  },
};
</script>
